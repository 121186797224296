import React from 'react';
import { version } from '../version';
import { createUseStyles } from 'react-jss';
import { useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  legal: {
    borderTop: 'solid 2px ' + theme.colorPrimary,
    lineHeight: '3rem',
    [theme.responsiveMd]: {
      lineHeight: '6rem',
    },
  },
  copyright: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    display: 'block',
    [theme.responsiveMd]: {
      display: 'inline',
    },
  },
  links: {
    float: 'left',
    [theme.responsiveMd]: {
      float: 'right',
    },
  },
  link: {
    ...theme.link,
    fontSize: '0.875rem',
  },
  spacer: {
    padding: '0 0.75rem',
    fontSize: '1rem',
    [theme.responsiveMd]: {
      padding: '0 1.5rem',
      fontSize: '1.25rem',
    },
  },
}));

const Legal = ({ productName }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={[classes.legal, 'clearfix'].join(' ')}>
      <span className={classes.copyright}>&copy; {new Date().getFullYear()} {productName} ({version})</span>
      <div className={classes.links}>
        <a className={classes.link} href="https://iaam.info/privacy-policy">Privacy Policy</a>
        <span className={classes.spacer}>|</span>
        <a className={classes.link} href="https://iaam.info/terms-of-use">Terms of Use</a>
      </div>
    </div>
  );
};

export default Legal;
