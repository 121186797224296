import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthentication } from '../auth/selectors';
import apiClient from '../utils/api';

const HospitalitySubscriptionButton = ({ subscriptionPlan, activationCode, onPaymentSuccess }) => {
  const { access_token } = useSelector(getAuthentication);
  const { active } = subscriptionPlan;
  useEffect(() => {
    const { planId, active } = subscriptionPlan;
    if (!active) return;
    const payload = { planId, activationCode };
    apiClient.post(
      'v1/payment/Hospitality/createPaymentSubscription', payload, {
        headers: {
          'Authorization': 'Bearer ' + access_token,
        },
      }).then(onPaymentSuccess, console.error);
  }, [subscriptionPlan, access_token, activationCode, onPaymentSuccess]);
  if (active) {
    return (<div>Activating...</div>);
  }
  return (<div/>);
};

HospitalitySubscriptionButton.defaultProps = {
  onPaymentSuccess: () => {},
};

export default HospitalitySubscriptionButton;
