import { Route, Switch } from 'react-router-dom';
import LoginPage from './LoginPage';
import React from 'react';
import CandidateRoutes from './candidate/Routes';
import EmployerRoutes from './employer/Routes';
import AdminRoutes from './admin/Routes';
import NotFound from './NotFound';
import JoinPage from './JoinPage';

const TopLevelRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={JoinPage}/>
      <Route exact path="/login" component={LoginPage}/>
      <Route path="/employer" component={EmployerRoutes}/>
      <Route path="/candidate" component={CandidateRoutes}/>
      <Route path="/admin" component={AdminRoutes}/>
      <Route component={NotFound}/>
    </Switch>
  );
};

export default TopLevelRoutes;
