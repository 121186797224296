import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles, useTheme } from "react-jss";
import { Button, Col, Container, Row } from 'react-bootstrap';
import arrowLineLeft from "../../icons/arrow-left-line.svg";
import { useHistory, useLocation } from "react-router-dom";
import { fetchEmployerOffer } from './actions';
import { getEmployerOffer } from './selectors';

const useStyles = createUseStyles({
  button: {
    minWidth: "33%",
    marginTop: "2em",
    color: ({ theme }) => theme.colorBackground,
    backgroundColor: ({ theme }) => theme.colorPrimary,
    borderColor: ({ theme }) => theme.colorPrimary,
  },
  h4: {
    fontWeight: 600,
    marginBottom: 32,
    display: "flex",
    jutifyContent: "center",
    "& img": {
      marginRight: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  offerWrapper: {
    padding: "30px 15px",
  },
  marginBottomRow: {
    marginBottom: "15px",
  },
});

const OfferActions = ({ offer }) => {
  const history = useHistory();
  if (offer.offerStatus !== 'rejected') {
    return null;
  }
  const handleMakeAnotherOffer = () => {
    history.push('makeOffer?id=' + offer.candidateId);
  };
  return (
    <Row>
      <Col>
        <Button onClick={handleMakeAnotherOffer} data-cy={'makeAnotherOffer'}>
          Make Another Offer
        </Button>
      </Col>
    </Row>
  );
};

const CandidateViewOffer = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const query = new URLSearchParams(location.search);
  const offerId = query.get("id");
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const offer = useSelector(getEmployerOffer);

  useEffect(() => {
    dispatch(fetchEmployerOffer(offerId));
  }, [dispatch, offerId]);

  function handleGoBack() {
    history.goBack();
  }

  const offerIdDisplay = offerId.split('-')[0];
  const jobDescriptionLines =
    offer.jobDescription && offer.jobDescription.length
      ? offer.jobDescription.split("\n")
      : [];

  return (
    <Container className={classes.offerWrapper} fluid data-cy={'offerView'}>
      <Row>
        <Col>
          <h4 className={classes.h4}>
            <img
              alt="arrow-left"
              onClick={handleGoBack}
              src={arrowLineLeft}
              width={16}
            />
            {" "}
            Offer to {offer.candidateName}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Offer ID: </strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>{offerIdDisplay}</Col>
      </Row>
      <Row>
        <Col>
          <strong>Job Title: </strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>{offer.jobTitle}</Col>
      </Row>
      <Row>
        <Col>
          <strong>Offered Rate: </strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>${offer.proposedHourlyRate} / hr</Col>
      </Row>
      <Row>
        <Col>
          <strong>Expected Start Date:</strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>{offer.startDate}</Col>
      </Row>
      <Row>
        <Col>
          <strong>Description:</strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col className="text-break">
          {jobDescriptionLines.map((line, idx) =>
            <React.Fragment key={idx}>
              {line}<br />
            </React.Fragment>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Status</strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>{offer.offerStatus}</Col>
      </Row>
      <OfferActions offer={offer} />
    </Container>
  );
};

export default CandidateViewOffer;
