import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { getPaymentInfo } from '../../auth/selectors';
import { authForcePaymentRefresh } from '../../auth/actions';
import PaymentProcessedNotifier from '../../components/PaymentProcessedNotifier';
import arrowLineLeft from '../../icons/arrow-left-line.svg';
import FormGroup from '../../components/FormGroup';
import FormLabel from '../../components/FormLabel';
import FormControl from '../../components/FormControl';
import Button from '../../components/Button';
import { getPromoInfo } from './selectors';
import { fetchSubscriptionPlanForPromoCode } from './actions';
import getPaymentProvider from '../../providers/payment';
import { isHospitalityNetwork } from '../../config';

const useStyles = createUseStyles(() => ({
  paymentWrapper: {
    marginTop: 32,
    '& h4': {
      fontWeight: 600,
      marginBottom: 32,
      display: 'flex',
      justifyContent: 'center',
      '& img': {
        marginRight: 10,
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
}));

const promoCodeLabel = isHospitalityNetwork ? 'Activation code' : 'Promotion code';

const PaymentRequest = () => {
  const history = useHistory();
  const classes = useStyles();
  const paymentInfo = useSelector(getPaymentInfo);
  const { promoCode, isValid, promoFeedback, subscriptionPlan } = useSelector(getPromoInfo);
  const [paymentRecentlyPaid, setPaymentRecentlyPaid] = useState(false);
  const [inputPromoCode, setInputPromoCode] = useState(promoCode);
  const dispatch = useDispatch();
  const handlePaymentSuccess = () => {
    dispatch(authForcePaymentRefresh());
    setPaymentRecentlyPaid(true);
  };
  const handlePromoCodeChange = (evt) => {
    setInputPromoCode(evt.target.value);
  };
  const handleApplyPromoCode = () => {
    dispatch(fetchSubscriptionPlanForPromoCode({ promoCode: inputPromoCode }));
  };
  useEffect(function() {
    if (subscriptionPlan) return;
    dispatch(fetchSubscriptionPlanForPromoCode({ promoCode: '' }));
  }, [subscriptionPlan, dispatch]);

  function handleGoBack () {
    history.goBack();
  }

  const { userPaid } = paymentInfo;

  if (userPaid && !paymentRecentlyPaid) {
    return (
      <div className={classes.paymentWrapper} data-cy={'activeSubscription'}>
        <h4>
          <img
            alt="arrow-left"
            onClick={handleGoBack}
            src={arrowLineLeft}
            width={16}
          />{' '}
          Active subscription detected. No further payment required.
        </h4>
      </div>
    );
  } else if (subscriptionPlan) {
    const paymentProvider = getPaymentProvider(subscriptionPlan.paymentProvider);
    return (
      <div className={classes.paymentWrapper} data-cy={'paymentRequest'}>
        <h4>
          <img
            alt="arrow-left"
            onClick={handleGoBack}
            src={arrowLineLeft}
            width={16}
          />{' '}
          {(subscriptionPlan.amount && 'Payment to ') || ''}Activate your Subscription
        </h4>
        <main>
          <p>
            {subscriptionPlan.description}
          </p>
          <FormGroup controlId="formPromoCode">
            <FormLabel>{promoCodeLabel}:</FormLabel>
            <FormControl
              type="text"
              name="promoCode"
              placeholder={'Enter Code (case insensitive)'}
              value={inputPromoCode}
              onChange={handlePromoCodeChange}
              disabled={userPaid}
              isInvalid={promoCode && !isValid}
              data-cy={'promotionCode'}
            />
            <Form.Control.Feedback type={'invalid'}>
              {promoCodeLabel} not recognized.
            </Form.Control.Feedback>
            {
              promoFeedback && (<div>{promoCodeLabel} accepted: {promoFeedback}</div>)
            }

          </FormGroup>
          <FormGroup>
            <Button width="25%" disabled={userPaid} onClick={handleApplyPromoCode} data-cy={'applyPromotionCode'}>
              Apply
            </Button>
          </FormGroup>
        </main>
        {paymentRecentlyPaid ? (
          <PaymentProcessedNotifier userPaid={userPaid}/>
        ) : (
          paymentProvider && paymentProvider.getPaymentButton(subscriptionPlan, promoCode, handlePaymentSuccess)
        )}
      </div>
    );
  } else {
    return (
      <div>Loading...</div>
    );
  }
};

export default PaymentRequest;
