import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import IAAMForm from '../../components/IaamForm';
import FormGroup from '../../components/FormGroup';
import FormLabel from '../../components/FormLabel';
import FormControl from '../../components/FormControl';
import Button from '../../components/Button';
import HeaderText from '../../components/HeaderText';
import { registerEmployer } from './actions';
import { Formik } from 'formik';
import { getEmployerValidationSchema, getRegistrationErrors } from './selectors';
import { isLoggedIn } from '../../auth/selectors';
import { createUseStyles } from 'react-jss';
import TermsOfService from './TermsOfService';
import { isHospitalityNetwork, registerEmployerAllowedReferrer } from '../../config';

const useStyles = createUseStyles({
  error: {
    color: 'red'
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    color: '#007bff',
    "&:hover": {
      textDecoration: 'underline',
    },
    "&:focus": {
      textDecoration: 'none',
    },
  },
});

const RegisterEmployer = ({ history }) => {
  const dispatch = useDispatch();
  const registrationErrors = useSelector(getRegistrationErrors);
  const loggedIn = useSelector(isLoggedIn);
  const classes = useStyles();
  const [showTOS, setShowTOS] = useState(false);

  if (loggedIn) {
    return (<div>
      You are already logged in. If you want to register a new employer, please logout first.
    </div>);
  }

  console.log(document.referrer, registerEmployerAllowedReferrer);
  if (isHospitalityNetwork && document.referrer !== registerEmployerAllowedReferrer) {
    return (<div>
      Your are not allowed to register on this website, please visit <a href="https://realhospitality.network/">https://realhospitality.network/</a>
    </div>);
  }

  function onShowTOS() {
    setShowTOS(true);
  }
  function closeTOS() {
    setShowTOS(false);
  }

  return (
    <>
      <TermsOfService show={showTOS} onHide={closeTOS}/>
      <Formik
        validationSchema={getEmployerValidationSchema}
        onSubmit={values => {
            dispatch(registerEmployer(values, { history }));
        }}
        initialValues={{
            firstName: '',
            lastName: '',
            companyName: '',
            emailAddress: '',
            password: '',
            confirmPassword: '',
            acceptedTOS: false
        }}>
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          isInvalid,
          errors,
        }) => (
          <IAAMForm onSubmit={handleSubmit}>

            <HeaderText level={4}>Employer Registration</HeaderText>

            <div className={classes.error}>{registrationErrors._}</div>

            <FormGroup controlId="formFirstName">
              <FormLabel required>First name</FormLabel>
              <FormControl type="text" name="firstName" value={values.firstName} onChange={handleChange}
                           isInvalid={touched.firstName && (errors.firstName || registrationErrors.firstName)}
                           data-cy={'firstName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'firstNameError'}>
                {(errors.firstName || registrationErrors.firstName)}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formLastName">
              <FormLabel required>Last name</FormLabel>
              <FormControl type="text" name="lastName" value={values.lastName} onChange={handleChange}
                           isInvalid={touched.lastName && (errors.lastName || registrationErrors.lastName)}
                           data-cy={'lastName'}
              />
                <Form.Control.Feedback type="invalid" data-cy={'lastNameError'}>
                    {errors.lastName || registrationErrors.lastName}
                </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formCompanyName">
              <FormLabel required>Company Name</FormLabel>
              <FormControl type="text" name="companyName" value={values.companyName} onChange={handleChange}
                           isInvalid={touched.companyName && (errors.companyName || registrationErrors.companyName)}
                           data-cy={'companyName'}
              />
                <Form.Control.Feedback type="invalid" data-cy={'companyNameError'}>
                    {errors.companyName || registrationErrors.companyName}
                </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formEmail">
              <FormLabel required>Company Email address</FormLabel>
              <FormControl type="email" name="emailAddress" value={values.emailAddress} onChange={handleChange}
                           isInvalid={touched.emailAddress && (errors.emailAddress || registrationErrors.emailAddress)}
                           data-cy={'email'}
              />
                <Form.Control.Feedback type="invalid" data-cy={'emailError'}>
                    {errors.emailAddress || registrationErrors.emailAddress}
                </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formPassword">
              <FormLabel required>Password</FormLabel>
              <FormControl type="password" name="password" value={values.password} onChange={handleChange}
                           isInvalid={touched.password && (errors.password || registrationErrors.password)}
                           data-cy={'password'}
              />
                <Form.Control.Feedback type="invalid" data-cy={'passwordError'}>
                    {errors.password || registrationErrors.password}
                </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formConfirmPassword">
              <FormLabel required>Confirm Password</FormLabel>
              <FormControl type="password" name="confirmPassword" value={values.confirmPassword} onChange={handleChange}
                           isInvalid={touched.confirmPassword && errors.confirmPassword}
                           data-cy={'confirmPassword'}
              />
                <Form.Control.Feedback type="invalid" data-cy={'confirmPasswordError'}>
                    {errors.confirmPassword}
                </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formAcceptTOS">
              <Form.Check type="checkbox"
                          label={<FormLabel required>I accept the <button
                            className={classes.linkButton}
                            onClick={onShowTOS} data-cy={'showTos'}>Terms of
                            Service</button></FormLabel>}
                          name="acceptedTOS"
                          value={values.acceptedTOS} onChange={handleChange}
                          isInvalid={touched.acceptedTOS && (errors.acceptedTOS || registrationErrors.acceptedTOS)}
                          data-cy={'acceptTos'}
              />
                <Form.Control.Feedback type="invalid" data-cy={'acceptTosError'}>
                    {errors.acceptedTOS || registrationErrors.acceptedTOS}
                </Form.Control.Feedback>
            </FormGroup>

            <FormGroup>
              <Button width="50%" type="submit" data-cy={'submit'}>
                Register
              </Button>
            </FormGroup>

          </IAAMForm>
        )}
      </Formik>
    </>
  );
};

export default RegisterEmployer;
