import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminEmployers } from './selectors';
import { Table } from 'react-bootstrap';
import { blockEmployer, fetchEmployers, unblockEmployer } from './actions';
import Paginator from '../../components/Paginator';
import Switch from '../../components/Switch';
import { useToasts } from 'react-toast-notifications';

const EmployersPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { results: candidates, total, errorMessage } = useSelector(getAdminEmployers);
  const [{ page, pageSize }, setState] = useState({ page: 1, pageSize: 25 });
  useEffect(() => {
    dispatch(fetchEmployers(page, pageSize));
  }, [dispatch, page, pageSize]);
  useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, { appearance: 'error' });
    }
  }, [errorMessage, addToast]);

  const handlePageChange = page => setState(state => ({ ...state, page }));
  const handleBlockSwitch = employerId => evt => {
    if (evt.target.checked) {
      dispatch(blockEmployer(employerId));
    } else {
      dispatch(unblockEmployer(employerId));
    }
  };
  return (
    <section>
      <Table striped responsive>
        <thead data-cy={'adminEmployerPage'}>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Title</th>
          <th>Company</th>
          <th>Email</th>
          <th>Phone #</th>
          <th>Subscribed</th>
          <th>Blocked</th>
        </tr>
        </thead>
        <tbody>
        {candidates.map(({
            employerId, firstName, lastName, title, companyName, emailAddress,
            phoneNumber, subscribed, blocked,
          }, index) =>
            <tr key={index} data-cy={'employer'}>
              <td>{employerId.split('-')[0]}</td>
              <td className="text-break">{firstName}</td>
              <td className="text-break">{lastName}</td>
              <td className="text-break">{title}</td>
              <td className="text-break">{companyName}</td>
              <td className="text-break">{emailAddress}</td>
              <td>{phoneNumber || 'N/A'}</td>
              <td>{subscribed ? 'YES' : 'NO'}</td>
              <td><Switch id={`blocked-${index}`} checked={blocked}
                          onChange={handleBlockSwitch(employerId)} data-cy={'blockEmployer'}/></td>
            </tr>,
        )}
        </tbody>
      </Table>
      <Paginator page={page} pageSize={pageSize} total={total}
                 onChange={handlePageChange}/>
    </section>
  );
};

export default EmployersPage;
