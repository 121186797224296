import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { END, eventChannel } from 'redux-saga';
import {
  ACCEPT_CANDIDATE_OFFER,
  ACCEPT_CANDIDATE_OFFER_FAILED,
  ACCEPT_CANDIDATE_OFFER_SUCCESS,
  CANDIDATE_CONFIRM_EMAIL,
  CANDIDATE_CONFIRM_EMAIL_FAILED,
  CANDIDATE_CONFIRM_EMAIL_SUCCESS,
  POPULATE_CANDIDATE_OFFER,
  POPULATE_CANDIDATE_OFFER_FAILED,
  POPULATE_CANDIDATE_OFFER_SUCCESS,
  POPULATE_CANDIDATE_OFFERS,
  POPULATE_CANDIDATE_OFFERS_FAILED,
  POPULATE_CANDIDATE_OFFERS_SUCCESS,
  REJECT_CANDIDATE_OFFER, REJECT_CANDIDATE_OFFER_FAILED,
  REJECT_CANDIDATE_OFFER_SUCCESS,
} from './actions';
import apiClient from '../../utils/api';
import {
  FETCH_CANDIDATE,
  FETCH_CANDIDATE_FAILED,
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATE_VIDEOS,
  FETCH_CANDIDATE_VIDEOS_FAILED,
  FETCH_CANDIDATE_VIDEOS_SUCCESS,
  FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE,
  FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_FAILED,
  FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_SUCCESS,
  LOGIN_USER_SUCCESS,
  MODIFY_CANDIDATE_DETAILS,
  MODIFY_CANDIDATE_DETAILS_FAILED,
  MODIFY_CANDIDATE_DETAILS_PROGRESS,
  MODIFY_CANDIDATE_DETAILS_SUCCESS,
  POPULATE_CANDIDATE_EMPLOYER_SHORTLIST,
  POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_FAILED,
  POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_SUCCESS,
  REGISTER_CANDIDATE,
  REGISTER_CANDIDATE_FAILED,
  REGISTER_CANDIDATE_SUCCESS,
  UPLOAD_CANDIDATE_VIDEO,
  UPLOAD_CANDIDATE_VIDEO_FAILED,
  UPLOAD_CANDIDATE_VIDEO_PROGRESS,
  UPLOAD_CANDIDATE_VIDEO_SUCCESS,
} from '../../actionTypes';
import {
  authForceRefresh,
  authInvalidError, authRefreshError,
  loginUser,
} from '../../auth/actions';
import { getAuthentication } from '../../auth/selectors';
import {
  authenticateRequest,
  authenticateRequestWithConfig, callTokenEndpointWithRepeat, persistAuth,
} from '../../auth/sagas';
import axios from 'axios';
import { permissions } from '../../security/permissions';

function * confirmEmail(action) {
  const { payload, meta } = action;
  try {
    const { candidateId, code } = payload;

    yield authenticateRequest(apiClient.post, 'v1/candidate/confirmEmail', { candidateId, code });

    yield put({
      type: CANDIDATE_CONFIRM_EMAIL_SUCCESS,
      payload: { candidateId, code },
      meta
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: CANDIDATE_CONFIRM_EMAIL_FAILED,
      payload: error,
      meta
    });
  }
}

export function * watchConfirmEmail() {
  yield takeLatest(CANDIDATE_CONFIRM_EMAIL, confirmEmail);
}

function hasARole (jwt) {
  if (jwt.roles.length === 0) {
    console.log('User confirmed their email but have no roles yet');
    throw new Error('User confirmed their email but have no roles yet');
  }
}

function * confirmEmailSuccess(action) {
  yield put(authForceRefresh(hasARole));
}

export function * watchConfirmEmailSuccess() {
  yield takeLatest(CANDIDATE_CONFIRM_EMAIL_SUCCESS, confirmEmailSuccess);
}

function* updateUploadStatus(chan, actionType) {
  try {
    let progress = 0;
    while (true) {
      let percentage = yield take(chan);
      if (percentage > progress) {
        progress = percentage;
        yield put({
          type: actionType,
          payload: { percentage }
        });
      }
    }
  } finally {
    console.log('updateUploadStatus done');
  }
}

export const uploadClient = axios.create({
  timeout: 10 * 60 * 1000, // 10 minutes
  headers: {
    'Accept': 'application/json',
  }
});

function * uploadCandidateVideo (action) {
  try {
    const {
      payload: { questionId, file, mirrorVideo },
      meta: { onComplete },
    } = action;

    const { name = 'video-recorded.webm', type } = file;
    const [contentType] = type.split(';');
    const res = yield authenticateRequestWithConfig(apiClient.get, 'v1/uploads', {
      params: { contentType, name }
    });
    const { fileId, url, skipAuth = false, fields = {} } = res.data;

    let progressEmitter;
    const chan = yield call(() => eventChannel(emitter => {
      progressEmitter = emitter;
      return () => progressEmitter = null;
    }));
    const onUploadProgress = (evt) => {
      const percentage = Math.round(evt.loaded / evt.total * 100);
      if (percentage < 100) {
        progressEmitter(percentage);
      } else {
        progressEmitter(END);
      }
    };
    const formData = new FormData();
    for (const [key, value] of Object.entries(fields)) {
      formData.append(key, value);
    }
    formData.append('file', file, name);
    const uploadEffect = skipAuth ? call : authenticateRequestWithConfig;
    yield all([
      uploadEffect(uploadClient.post, url, formData, { onUploadProgress }),
      call(updateUploadStatus, chan, UPLOAD_CANDIDATE_VIDEO_PROGRESS),
    ]);

    const commandResponse = yield authenticateRequest(apiClient.post,
      'v1/candidateVideo/upload', { questionId, fileId, mirrorVideo });
    const { videoId } = commandResponse.data;
    yield put({
      type: UPLOAD_CANDIDATE_VIDEO_SUCCESS,
      payload: { questionId, videoId },
    });
    yield call(onComplete);
  } catch (error) {
    console.error(error);
    yield put({
      type: UPLOAD_CANDIDATE_VIDEO_FAILED,
      payload: error,
    });
  }
}

export function* watchUploadCandidateVideo() {
  yield takeLatest(UPLOAD_CANDIDATE_VIDEO, uploadCandidateVideo);
}

export function * fetchCandidateVideos() {
  try {
    const page = 1, pageSize = 20;
    const res = yield authenticateRequestWithConfig(apiClient.get, '/v1/candidateVideo/query', {
      params: {
        order: 'uploaded_at DESC',
        skip: (page - 1) * pageSize,
        limit: pageSize,
      },
    });
    yield put({
      type: FETCH_CANDIDATE_VIDEOS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: FETCH_CANDIDATE_VIDEOS_FAILED,
      payload: error.message,
    });
  }
}

export function * watchFetchCandidateVideos() {
  yield takeLatest(FETCH_CANDIDATE_VIDEOS, fetchCandidateVideos);
}

function* registerCandidate(action) {
  let response;
  try {
    const { password, firstName, lastName, emailAddress, acceptedTOS, jobCategories } = action.payload;

    response = yield call(apiClient.post, 'v1/candidate/register', { password, firstName, lastName, emailAddress, acceptedTOS, jobCategories });

    yield put({
      type: REGISTER_CANDIDATE_SUCCESS,
      payload: { password, firstName, lastName, emailAddress, acceptedTOS, jobCategories },
      meta: { ...action.meta, response }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: REGISTER_CANDIDATE_FAILED,
      payload: { error },
      meta: { ...action.meta, response }
    });
  }
}

export function* watchRegisterCandidate() {
  yield takeLatest(REGISTER_CANDIDATE, registerCandidate);
}

function * registerCandidateSuccess(action) {
  const { emailAddress: username, password } = action.payload;
  const { history } = action.meta;
  const from = '/confirmemail';
  yield put(loginUser({ username, password }, history, from));
}

export function* watchRegisterCandidateSuccess() {
  yield takeLatest(REGISTER_CANDIDATE_SUCCESS, registerCandidateSuccess);
}


function * populateCandidateOffers(action) {
  try {
    const { candidateId } = action.payload;

    const result = yield authenticateRequestWithConfig(
      apiClient.get,
      'v1/offers/queryCandidateOffers',
      { params: { candidateId } },
    );

    yield put({
      type: POPULATE_CANDIDATE_OFFERS_SUCCESS,
      payload: { result }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: POPULATE_CANDIDATE_OFFERS_FAILED,
      payload: error,
    });
  }
}

export function * watchPopulateCandidateOffers() {
  yield takeLatest(POPULATE_CANDIDATE_OFFERS, populateCandidateOffers);
}

function * loginCandidate(action) {
  try {
    const { history } = action.meta;
    const { jwt, access_token } = yield select(getAuthentication);
    if (!history) {
      // no page history = refresh, so we don't record the login again
      return;
    }
    const { user_type } = jwt;
    if ( user_type.toLowerCase() !== 'candidate' ) {
      return;
    }
    // This call is recording the login for the user type
    yield authenticateRequest(apiClient.post, `v1/candidate/login`, { access_token });
  } catch (error) {
    // This is only recording the login, so on failure we don't fail
    console.warn(error);
  }
}

export function * candidateWatchLoginSuccess() {
  yield takeLatest(LOGIN_USER_SUCCESS, loginCandidate);
}

function * populateCandidateOffer(action) {
  try {
    const { offerId } = action.payload;

    const result = yield authenticateRequestWithConfig(
      apiClient.get,
      'v1/offers/queryCandidateOffer',
      { params: { offerId } },
    );

    yield put({
      type: POPULATE_CANDIDATE_OFFER_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: POPULATE_CANDIDATE_OFFER_FAILED,
      payload: error,
    });
  }
}

export function * watchPopulateCandidateOffer() {
  yield takeLatest(POPULATE_CANDIDATE_OFFER, populateCandidateOffer);
}

function * candidateAcceptOffer(action) {
  try {
    const { offerId } = action.payload;

    const result = yield authenticateRequest(apiClient.post, 'v1/offers/candidateAcceptOffer', { offerId });

    yield put({
      type: ACCEPT_CANDIDATE_OFFER_SUCCESS,
      payload: { result }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ACCEPT_CANDIDATE_OFFER_FAILED,
      payload: error,
    });
  }
}

export function * watchCandidateAcceptOffer() {
  yield takeLatest(ACCEPT_CANDIDATE_OFFER, candidateAcceptOffer);
}

function * candidateRejectOffer(action) {
  try {
    const { offerId } = action.payload;

    const result = yield authenticateRequest(apiClient.post, 'v1/offers/candidateRejectOffer', { offerId });

    yield put({
      type: REJECT_CANDIDATE_OFFER_SUCCESS,
      payload: { result }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: REJECT_CANDIDATE_OFFER_FAILED,
      payload: error,
    });
  }
}

export function * watchCandidateRejectOffer() {
  yield takeLatest(REJECT_CANDIDATE_OFFER, candidateRejectOffer);
}

function * fetchCandidate(action) {
  try {
    const { candidateId } = action.payload;

    const { data } = yield authenticateRequest(apiClient.get, `v1/candidate/${candidateId}`);

    yield put({
      type: FETCH_CANDIDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: FETCH_CANDIDATE_FAILED,
      payload: error,
    });
  }
}

export function * watchFetchCandidate() {
  yield takeLatest(FETCH_CANDIDATE, fetchCandidate);
}

function validateCanUploadVideos (canUploadVideos) {
  return function (jwtToken) {
    if (jwtToken.permissions.includes(permissions.canUploadVideo) !== canUploadVideos) {
      console.log(`ReadModel has yet to acknowledge canUploadVideos permission to ${canUploadVideos}`);
      throw new Error(`ReadModel has yet to acknowledge canUploadVideos permission to ${canUploadVideos}`);
    }
  };
}

function * refreshToken(refresh_token, validate = () => {}) {
  try {
    const params = {
      refresh_token,
      grant_type: 'refresh_token',
    };
    const { data: token } = yield call(callTokenEndpointWithRepeat, validate, params);
    persistAuth(token);
    return token;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(authInvalidError(error.response));
      } else {
        yield put(authRefreshError(error.response));
      }
    } else {
      yield put(authRefreshError(error));
    }
    return null;
  }
}

function * modifyCandidateDetails(action) {
  try {
    const { resumeFile, ...payload } = action.payload;

    let resumeFileId;
    if (resumeFile) {
      const { name, type } = resumeFile;
      const [contentType] = type.split(';');
      const res = yield authenticateRequestWithConfig(
        apiClient.get,
        'v1/uploads',
        {
          params: { contentType, name, type: 'document' }
        },
      );
      const { fileId, url, skipAuth = false, fields = {} } = res.data;

      let progressEmitter;
      const chan = yield call(() => eventChannel(emitter => {
        progressEmitter = emitter;
        return () => progressEmitter = null;
      }));
      const onUploadProgress = (evt) => {
        const percentage = Math.round(evt.loaded / evt.total * 100);
        if (percentage < 100) {
          progressEmitter(percentage);
        } else {
          progressEmitter(END);
        }
      };
      const formData = new FormData();
      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value);
      }
      formData.append('file', resumeFile, name);
      const uploadEffect = skipAuth ? call : authenticateRequestWithConfig;
      yield all([
        uploadEffect(uploadClient.post, url, formData, { onUploadProgress }),
        call(updateUploadStatus, chan, MODIFY_CANDIDATE_DETAILS_PROGRESS),
      ]);
      resumeFileId = fileId;
    } else if (resumeFile === null) {
      resumeFileId = '';
    }

    const { data } = yield authenticateRequest(
      apiClient.post,
      `v1/candidateDetails/ModifyCandidate`,
      { ...payload, resumeFileId },
    );
    let token = {};
    const { refresh_token } = yield select(getAuthentication);
    token = yield call(refreshToken, refresh_token,
      validateCanUploadVideos(action.payload.legalToWorkInCountry));
    yield put({
      type: MODIFY_CANDIDATE_DETAILS_SUCCESS,
      payload: data,
      meta: { token }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: MODIFY_CANDIDATE_DETAILS_FAILED,
      payload: error,
    });
  }
}

export function * watchModifyCandidateDetails() {
  yield takeLatest(MODIFY_CANDIDATE_DETAILS, modifyCandidateDetails);
}

function* populateCandidateEmployerShortlist(action) {
  let response;
  try {
    const { candidateId } = action.payload;

    response = yield authenticateRequest(apiClient.get, `v1/candidate/${candidateId}/queryCandidateEmployerShortlist`);

    yield put({
      type: POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_SUCCESS,
      payload: { response },
      meta: { response }
    });
  } catch (error) {

    console.error(error);
    yield put({
      type: POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_FAILED,
      payload: { error },
      meta: { response }
    });
  }
}

export function* watchPopulateCandidateEmployerShortlist() {
  yield takeLatest(POPULATE_CANDIDATE_EMPLOYER_SHORTLIST, populateCandidateEmployerShortlist);
}

function* fetchSubscriptionPlanForPromoCode(action) {
  let response;
  try {
    const { promoCode } = action.payload;
    response = yield authenticateRequestWithConfig(apiClient.get, `v1/payment/subscriptionPlans/findOne`, {
      params: { promoCode }
    });
    yield put({
      type: FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_SUCCESS,
      payload: response.data,
      meta: { response }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_FAILED,
      payload: error,
      meta: { response }
    });
  }

}

export function* watchFetchSubscriptionPlanForPromoCode() {
  yield takeLatest(FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE, fetchSubscriptionPlanForPromoCode);
}
