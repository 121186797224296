import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  text: {
    position: 'absolute',
    top: '50px',
    right: '50px',
    fontFamily: 'Menlo, monospace',
    fontSize: '28px',
    textShadow: '1px 2px rgba(0, 0, 0, 0.5)',
  },
  icon: {
    width: '16px',
    height: '16px',
    background: '#e55226',
    borderRadius: '50%',
    float: 'left',
    margin: '2px 8px',
    marginLeft: 0,
  },
});

const pad = (unit) => {
  const str = '' + unit;
  const pad = '00';
  return pad.substring(0, pad.length - str.length) + str;
};

const getState = (seconds) => {
  const minutes = Math.floor(seconds / 60);

  const humanTime =
    minutes !== 0
      ? `${minutes}:${pad(seconds - minutes * 60)}`
      : `${seconds - minutes * 60}s`;

  return {
    seconds: seconds,
    human: humanTime
  };
};

const Timer = ({
  timeLimit = 0,
  defaultText = '0:00',
}) => {
  const classes = useStyles();
  const [state, setState] = useState(getState(timeLimit && timeLimit / 1000));
  useEffect(() => {
    const timer = setInterval(() => {
      setState(({ seconds }) => getState(timeLimit ? seconds - 1 : seconds + 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeLimit]);
  return (
    <div className={classes.text}>
      <div className={classes.icon} />
      {state.human || defaultText}
    </div>
  );
};

export default Timer;